/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import {
  metodologiaPautada,
  content,
  iconsBg,
  desktop,
  mobile
} from "../assets/styles/PrimeiroPasso.style";

const MetodologiaPautadaContent = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
        setScrollPosition(window.scrollY/2);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  return (
    <section css={metodologiaPautada}>
      <Container fluid>
        <Row className="title-content">
          <Col css={content} lg={7} md={12} sm={12} className="px-0">
            <div className="col-content">
              <h2>Metodologia pautada na neuroeducação</h2>
              <p>Os alunos da Educação Infantil Kingdom são expostos a todos os estímulos necessários para uma formação completa. Mas antes disso, são abraçados pelo principal estímulo, o amor!</p>
              <p>Dentro da filosofia Kingdom acreditamos que não existe aprendizado sem conexão humana e que para o crescimento pleno acontecer é preciso de afeto e carinho. O tempo de concentração da criança é limitado e seu maior ganho vem do aprendizado com emoção.</p>
            </div>
          </Col>
          <Col css={desktop} className="img-container px-0" lg={5} md={12} sm={12}>
            <div css={iconsBg} className="bg-image" />
          </Col>
        </Row>
      </Container>
      <Col css={mobile} className="img-container px-0" lg={5} md={12} sm={12}>
        <div css={iconsBg} className="bg-image" />
      </Col>
    </section>
  );
};

export default MetodologiaPautadaContent;
