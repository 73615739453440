import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderTitle from "@/components/HeaderTitleEI";
import MetodologiaPautada from "@/components/MetodologiaPautadaContent";
import EstimulamosAutonomia from "@/components/EstimulamosAutonomiaContent";
import CTAMaisTempoParaObem from "@/components/CTAMaisTempoParaObem";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import EducacaoInfantilSliderContainer from '@/components/EducacaoInfantilSliderContainer';
import HeaderOne from '@/components/header-one';
import SEO from '../components/seo'

const EducacaoInfantilPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout>
          <SEO
            title="Educação Infantil em Brasília| Kingdom School"
            description="Um ambiente seguro e estimulante para o desenvolvimento integral das crianças. Conheça a proposta pedagógica Kingdom para a Educação Infantil."
          />
          <HeaderOne />
          <HeaderTitle />
          <MetodologiaPautada />
          <EducacaoInfantilSliderContainer />
          <EstimulamosAutonomia />
          <CTAMaisTempoParaObem />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default EducacaoInfantilPage;
