/** @jsx jsx */
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import { useMediaQuery } from 'react-responsive';
import { Link } from "gatsby";
import {
  abAgency,
  content,
} from "../assets/styles/AboutTwo.styles";

import ImgEscolaDePresente from "../assets/images/LP-kingdom/estimulamos-autonomia.png";
import CircleSvg from "../assets/images/LP-kingdom/circle.svg";

const AboutTwo = () => {
  const [setCounter] = useState({
    startCounter: false,
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 850px)'
  })

  return (
    <section css={abAgency} style={{ padding: "90px 0px" }}>
      {isMobile && 
      <Col
      className="img-container px-0"
      data-aos="fade-right"
      lg={6}
      md={12}
      sm={12}
    >
      <img src={ImgEscolaDePresente} alt=" " />
    </Col>}
      <Container fluid>
        <Row className="title-content">
        {!isMobile &&
          <Col
            className="img-container px-0"
            data-aos="fade-right"
            lg={6}
            md={12}
            sm={12}
          >
            <img src={ImgEscolaDePresente} alt=" " />
          </Col>}
          <Col css={content} lg={6} md={12} sm={12} >
            <div>
              <h1 style={{ maxWidth: 450 }}>Estimulamos autonomia e senso de pertencimento </h1>
              <p style={{ maxWidth: 450, marginBottom: 24 }}>Dentro da rotina escolar, os processos são organizados em instruções claras e rituais que demonstram essas habilidades. Para estimular o potencial das crianças, as turmas do Infantil contam com:</p>
              <ul>
                <li><img src={CircleSvg} /> Salas amplas</li>
                <li><img src={CircleSvg} /> Número de alunos reduzido</li>
                <li><img src={CircleSvg} /> Atividades de psicomotricidade, espanhol, inglês, música e arte</li>
                <li style={{ marginBottom: 40 }}><img src={CircleSvg} /> Xadrez, dança, capoeira, judô e ballet</li>
              </ul>
              <div className="btn-section">
                <Link to="/fale-conosco" style={{ color: '#FFFFFF' }}>Quero agendar uma visita</Link>
              </div>
            </div>
          </Col>        
        </Row>
      </Container>
    </section>
  );
};

export default AboutTwo;
