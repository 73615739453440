/** @jsx jsx */
import React, { useEffect } from "react"
import { jsx } from '@emotion/react'
import { card, col_card, SectionStyled } from '../assets/styles/EducacaoInfantilSliderContainer.styles'
import { Col, Container, Row } from 'react-bootstrap'
import CardSlider from './cardSwiper'
import MapLocation from '../assets/icons/mapLocationPurple-icon.svg'

const EducacaoInfantilSliderContainer = () => {
  return (
    <section css={SectionStyled}>
      <Container fluid>
        <Row>
          <Col
            css={col_card}
            data-aos="fade-right"
            lg={4}
            md={4}
            sm={12}
          >
            <div css={card} id="estrutura-kids">
              <h2>
              Unidade Infantil: aconchegante e acolhedora
              </h2>
              <h3>
                <img src={MapLocation} /> Educação Infantil - QL 6 - Lago Sul
              </h3>
              <p>
                Em um privilegiado ponto de acesso, nossa estrutura da Educação Infantil conta com uma área de 6.545m². Conforto e comodidade são marcas do Kingdom Kids, com salas de aula amplas, de fácil acesso e com banheiros em todas as salas a partir das turmas de Kids 2 (crianças de 2 anos). São quase 2.000m² de área verde, um espaço exclusivo que proporciona aos alunos um brincar cheio de envolvimento, criatividade e beleza.
              </p>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12}>
            <CardSlider />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EducacaoInfantilSliderContainer
