/** @jsx jsx */
import { jsx } from "@emotion/react";

import { containerCard } from "../assets/styles/CardSwiper.styles";
import "swiper/swiper-bundle.min.css";
import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// import photoTeacher from '../assets/images/LP-images/photo-teacher.png'
import Img1 from "../assets/images/LP-kingdom/infantil/img-1.png";
import Img2 from "../assets/images/LP-kingdom/infantil/img-2.png";
import Img3 from "../assets/images/LP-kingdom/infantil/img-3.png";
import Img4 from "../assets/images/LP-kingdom/infantil/img-4.png";
import Img5 from "../assets/images/LP-kingdom/infantil/img-5.png";
import Img6 from "../assets/images/LP-kingdom/infantil/img-6.png";

function CardSlider() {
  return (
    <div css={containerCard}>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={0}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        breakpoints={{
          851: {
            spaceBetween: 8,
          },
          510: {
            spaceBetween: 8,
          },
        }}
        className="swiper"
      >
        <SwiperSlide>
          <div className="swiper-slide__container">
            <img src={Img1} alt=" " />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
            <img src={Img2} alt=" " />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
            <img src={Img3} alt=" " />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
            <img src={Img4} alt=" " />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
            <img src={Img5} alt=" " />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
            <img src={Img6} alt=" " />
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="swiper-pagination" />
    </div>
  );
}

export default CardSlider;
